<template>
  <!-- 活动新闻 -->
  <div>
    <div class="item" v-for="(item, index) in newslist" :key="index" @click="todetail(item.id)">
      <div class="item-left">
        <el-image class="elimage" :src="item.cover" />
      </div>
      <div class="item-right">
        <div class="item-right-head">
          <div class="item-right-head-left">
            <!-- <el-image class="elimage" :src="icon" /> -->
            <div class="content-title">
              {{ item.title }}
            </div>

          </div>
          <div class="item-right-text">
            {{ item.sub_title }}
          </div>
        </div>

        <div class="item-right-head-right">
          <el-image class="time-icon" :src="timeicon" />
          <div class="time-title">{{ item.updated_at }}</div>
        </div>
      </div>
    </div>

    <div class="elpagination">
      <div class="elpagination-title">共{{ total }}条</div>
      <el-pagination background layout="prev, pager, next" :total="total">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
import { getNewsList } from "@/api/news";
export default {
  name: "mainpage",
  components: {},
  data() {
    return {
      total: 0,
      newslist: [],
      icon: require("@/assets/矩形 88.png"),
      test: require("@/assets/test.png"),
      timeicon: require("@/assets/时间 钟表.png"),
    };
  },
  computed: {},
  created() {
    this.getnewslist({ type: "0" });
  },
  methods: {
    todetail(id) {
      this.$router.push({
        path: "/home/news/newsdetails",
        query: {
          id: id,
        },
      });
    },
    getnewslist(params) {
      this.newslist = [];
      getNewsList(params)
        .then((response) => {
          if (response.data.data && response.data.data.length > 0) {
            this.total = response.data.total;
            response.data.data.forEach((item) => {
              this.newslist.push({
                cover: item.cover,
                id: item.id,
                sub_title: item.sub_title,
                title: item.title,
                updated_at: dayjs
                  .unix(item.updated_at)
                  .format("YYYY-MM-DD HH:mm:ss"),
              });
            });
          }
        })
        .catch((error) => {
        });
    },
  },
};
</script>
<style scoped lang="scss">
.item {
  // border: 1px solid red;
  height: 150px;
  background: #ffffff;
  // box-shadow: 0px 1px 0px 0px rgba(223, 223, 223, 0.92);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  cursor: pointer;
  .item-left {
    //  border: 1px solid red;
    margin-right: 10px;
    flex-basis: 240px;
    height: 100%;
    .elimage {
      height: 100%;
      width: 100%;
    }
  }
  .item-right {
    flex: 1;
    height: 100%;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: space-between;
    justify-content: space-between;
    .item-right-head {
      // display: flex;
      // justify-content: space-between;
      // align-items: flex-start;
      .item-right-head-left {
        // display: flex;
        // justify-content: center;
        // align-items: center;
        .elimage {
          margin-right: 5px;
          width: 12px;
          height: 16px;
        }
        .content-title {
          font-size: 16px;
          font-weight: 500;
          color: #222222;
          user-select: none;
          // white-space: nowrap;
        }
      }
    }
    .item-right-head-right {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .time-icon {
        margin-right: 2px;
        width: 16px;
        height: 16px;
      }
      .time-title {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        margin-left: 5px;
        // white-space: nowrap;
      }
    }
    .item-right-text {
      margin-top: 10px;
      // margin-left: 17px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
}
.item-grey {
  background: #f7f7f7;
}
.elpagination {
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  .elpagination-title {
    margin-right: 4px;
  }
}
</style>

</style>
