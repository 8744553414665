<template>
  <div class="mainpage">
    <div class="head">
      <!-- <div class="head-title">新闻资讯</div> -->
      <div class="head-right">
        <div
          :class="
            btname == 'left'
              ? 'head-right-bt head-right-bt-active'
              : 'head-right-bt'
          "
          @click="clickbt('left', 'Specialinformation')"
        >
          专题资讯
        </div>
        <div
          :class="
            btname == 'right'
              ? 'head-right-bt head-right-bt-active'
              : 'head-right-bt'
          "
          @click="clickbt('right', 'Eventnews')"
        >
          活动新闻
        </div>
      </div>
    </div>
    <div class="line"></div>
    <component :is="componentId"></component>
  </div>
</template>

<script>
import Eventnews from "./eventnews.vue";
import Specialinformation from "./specialinformation.vue";

export default {
  name: "mainpage",
  components: {
    Eventnews,
    Specialinformation,
  },
  data() {
    return {
      componentId: "Specialinformation",
      btname: "left",
      newslist: [],
      total: 0,
      icon: require("@/assets/矩形 88.png"),
      test: require("@/assets/test.png"),
      timeicon: require("@/assets/more.png"),
    };
  },
  computed: {},
  methods: {
    clickbt(btname, componentId) {
      this.btname = btname;
      this.componentId = componentId;
    },

  },
};
</script>
<style scoped lang="scss">
.mainpage {
  .head {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .head-title {
      font-size: 20px;
    
      font-weight: 400;
      color: #3D84FF;
      user-select: none;
      // white-space: nowrap;
    }
    .head-right {
      display: flex;
      justify-content: center;
      align-items: center;
      .head-right-bt {
        // margin-right: 6px;
        width: 90px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background: #ffffff;
        // border-bottom: 2px solid transparent;
        user-select: none;
        cursor: pointer;
        margin-bottom: -1px;
        font-weight: bold;
      }
      .head-right-bt-active {
        color: #3D84FF;
        border-bottom: 2px solid #3D84FF;
      }
    }
    .elinput {
      width: 300px;
      ::v-deep .el-input__suffix {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .line {
    // margin-top: 14px;
    // margin-bottom: 4px;
    border-bottom: 1px solid #cccccc;
  }
}
</style>
