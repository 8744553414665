import request from '@/utils/request'

//获取门户首页新闻
export function getNewsIndexList(params) {
    return request({
      url: '/portal/news/get-index-list',
      method: 'GET',
      params
    })
  }

  //获取门户资讯列表
export function getNewsList(params) {
    return request({
      url: '/portal/news/get-list',
      method: 'GET',
      params
    })
  }

   //新闻资讯详情
export function getInfo(params) {
    return request({
      url: '/portal/news/get-info',
      method: 'GET',
      params
    })
  }